import React from "react"

import { Flex, Box, Columns, Text } from "@sqymagma/elements"
import Tick from "../components/tick"
import Btn from "../components/button"
import { Link } from "gatsby"

import data from "../data/m25"

const BannerHome = () => {
  return (
    <Box width="100%" m="0px">
      <Columns cols={[1, 2]}>
        <Flex
          bg="brand02"
          flexDirection="column"
          justifyContent="center"
          align-Items="center"
          minHeight={["100vw", "50vw"]}
        >
          <Flex m={[40, 40, 72, 100, 208]} textAlign="left" flexDirection="column">
            <Text textStyle="h4">{data.Title01}</Text>
            <Tick />
            <Text textStyle="p" color="text03" mb="24px">
              {data.p01}
            </Text>
            <Flex justifyContent="left">
              <Btn
                as="a"
                type="link"
                size="big"
                href={data.link1}
                id={data.id1}
                margin="0 !important"
              >
                {data.cta01}
              </Btn>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          flexDirection="column"
          justifyContent="center"
          align-Items="center"
          bg="text02"
          minHeight={["100vw", "50vw"]}
        >
          <Flex m={[40, 40, 72, 100, 208]} textAlign="left" flexDirection="column">
            <Text textStyle="h4" color="text01">
              {data.Title02}
            </Text>
            <Tick />
            <Text textStyle="p" color="text03" mb="24px">
              {data.p02}
            </Text>
            <Flex justifyContent="left">
              <Btn
                as="a"
                type="link"
                size="big"
                href={data.link2}
                id={data.id2}
                margin="0 !important"
              >
                {data.cta02}
              </Btn>
            </Flex>
          </Flex>
        </Flex>
      </Columns>
    </Box>
  )
}

export default BannerHome
