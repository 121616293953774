import React from "react"
import Layout from "../modules/layout"
import Hero03 from "../modules/m03_hero"
import CardHero03 from "../components/CardHero03"
import Carousel03 from "../components/Carousel03"
import Block from "../components/block"
import BannerHome from "../modules/m25_BannerHome"
import LeaveData from "../modules/m21_leaveData"
import Casos from "../modules/m20_casos"
import Statistics from "../modules/m04_Statistics"
import MainPoints from "../modules/m05_MainPoints"
import Card from "../components/cardCaso"
import datacasos from "../data/casos"
import m21A from "../data/m21A"

const IndexPage = () => {
  return (
    <Layout
      title="Cambio de uso de local a vivienda Madrid | Living Alternatives"
      desc="Transformamos locales en viviendas totalmente reformadas y decoradas.Buscamos obtener altas rentabilidades de las viviendas segregadas. Déjanos asesorarte."
      keywords="Cambio de uso de local a vivienda,
        Cambio de uso de local a vivienda Madrid,
        Cambio de uso de local comercial a vivienda,
        Transformar local en vivienda"
      url=""
    >
      <Hero03>
        <Carousel03>
          <CardHero03 filename="portada_01_zuera.jpg" alt="De una pescadería a un estudio" />
          <CardHero03
            filename="portada_02_puerto_real.jpg"
            alt="De una clínica dental a dos viviendas"
          />
          <CardHero03 filename="portada_03_sincelejo.jpg" alt="Obra en calle Sincelejo" />
          <CardHero03
            filename="portada_04_juan_de_iziar.jpg"
            alt="De un restaurante a tres viviendas"
          />
        </Carousel03>
      </Hero03>
      <Statistics />

      <Block>
        <Casos>
          <Card
            datacasos={datacasos[0]}
            tagmanagerid="TM10-Home-MóduloCasosPracticos-JuanIziar"
            title="De un restaurante a tres viviendas"
          ></Card>
          <Card
            datacasos={datacasos[1]}
            tagmanagerid="TM11-Home-MóduloCasosPracticos-SanVenancio"
            title="De un local en bruto a dos viviendas"
          ></Card>
          <Card
            datacasos={datacasos[2]}
            tagmanagerid="TM12-Home-MóduloCasosPracticos-PuertoReal"
            title="De una clínica dental a dos viviendas"
          ></Card>
        </Casos>
      </Block>

      <MainPoints />
      <BannerHome />
      <LeaveData
        data={m21A}
        tagidsubemail="TM81-Suscribete-MóduloEmail-BotonNext"
        tagidsubllamada="TM82-Suscribete-Llamada-TextoLlamada"
      />
    </Layout>
  )
}

export default IndexPage
