const m05 = [
  {
    title: "Experiencia",
    icon: "ilexp",
    text:
      "En los últimos 20 meses hemos gestionado el cambio de uso a vivienda de 35 locales convirtiendo los mismos, tras un proceso de segregación, en 105 viviendas.",
  },
  {
    title: "Alquiler",
    icon: "ilalquiler",
    text:
      "Las viviendas resultantes se pueden alquilar aprox. a partir del séptimo mes desde la compra, generando rentabilidades entre el 8-11% de la inversión efectuada (en función de la ubicación geográfica de la vivienda).",
  },
  {
    title: "Venta",
    icon: "ilventa",
    text:
      "Una vez obtenida la licencia de vivienda (L.P.O.), a partir del mes 7-9, la vivienda podría ser vendida consiguiendo rentabilidades de hasta el 30 - 50%* en función de la ubicación del local y/o del número de viviendas obtenidas tras la reforma efectuada.",
  },
]

export default m05
