import React from "react"
import styled from "styled-components"
import NukaCarousel from "nuka-carousel"
import "./Carousel03.css"

const CSSNukaCarousel = styled(NukaCarousel)`
  position: absolute !important;
  height: 100vh;
`

const Carousel03 = ({ children, ...props }) => {
  return (
    <CSSNukaCarousel
      className="headcarousel"
      autoplay="true"
      autoplayInterval="3000"
      pauseOnHover="false"
      transitionMode="fade"
      enableKeyboardControls="true"
      wrapAround="true"
      swiping="false"
      withoutControls="true"
      {...props}
    >
      {children}
    </CSSNukaCarousel>
  )
}

export default Carousel03
