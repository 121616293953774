const m04 = {
  pretitle: "Nuestras experiencia en los últimos 20 meses",
  title: "Cambio de uso de local a vivienda en Madrid",
  subtitle:
    "Invierte en la adquisición de locales comerciales en Madrid, que tras un proceso de cambio de uso y segregación se convertirán en viviendas reformadas y decoradas, con el objetivo de conseguir altas rentabilidades vía alquiler de las nuevas viviendas.",
  cards: [
    {
      title: "105",
      icon: "home",
      text: "Viviendas resultantes",
    },
    {
      title: "35",
      icon: "icninquilino",
      text: "locales cambiados de uso",
    },
    {
      title: "40m2",
      icon: "",
      text: "Tamaño medio vivienda resultante.",
    },
    {
      title: "875",
      icon: "",
      text: "Alquiler mensual medio",
    },
    {
      title: "8-11%",
      icon: "",
      text: "Rentabilidad del alquiler",
    },
  ],
}

export default m04
