import React from "react"
import { Box, Columns, Text, Flex } from "@sqymagma/elements"
import styled from "styled-components"
import NukaCarousel from "nuka-carousel"
import Block from "../components/block"
import { IcnSystem } from "../components/IconSystem/IcnSystem"
import data from "../data/m04"
import AspectRatioBox from "../components/AspectRatioBox"

const Title = styled(Text)`
  font-family: "DM Serif Display", serif;
  font-size: 48px;
  line-height: 64px;

  ${p => p.theme.mq.s} {
    ${p => p.theme.textStyle.h3}
  }
  ${p => p.theme.mq.xl} {
    ${p => p.theme.textStyle.h2}
  }
`
const Subtitle = styled(Text)`
  font-family: Rubik, sans-serif;
  font-size: 18px;
  line-height: 24px;

  ${p => p.theme.mq.s} {
    ${p => p.theme.textStyle.p1_t}
  }
  ${p => p.theme.mq.xl} {
    ${p => p.theme.textStyle.p_t}
    margin-top: 8px;
  }
`
const Icn = styled(IcnSystem)``

const StyledNuca = styled(NukaCarousel)`
  & ul {
    li {
      display: flex !important;
      justify-content: center;
      height: auto !important;
    }
  }
`
const CssTitleSubido = styled(Box)`
  transform: translateY(-120px);
  ${p => p.theme.mq.s} {
    transform: translateY(-80px);
  }
  ${p => p.theme.mq.m} {
    transform: translateY(-96px);
  }
  ${p => p.theme.mq.l} {
    transform: translateY(-112px);
  }
  ${p => p.theme.mq.xl} {
    transform: translateY(-160px);
  }
`

const Card = ({ cdata }) => {
  const icon =
    cdata.icon !== "" ? (
      <Icn
        align-self="center"
        name={cdata.icon}
        fill="text01"
        height={["32px", "19px", "20px", "26px", "32px"]}
        my={["8px", "4px", "8px"]}
        mx={["4px", "2px", "2px", "2px", "4px"]}
      />
    ) : (
      <></>
    )

  return (
    <AspectRatioBox type="1to1" bg="brand02" zIndex="4" position="relative">
      <Flex
        position="absolute"
        width="100%"
        height="100%"
        top="0"
        left="0"
        flexDirection="column"
        justifyContent="center"
        flex="1"
        px={["16px", "8px", "16px"]}
      >
        <Flex flexDirection="row" justifyContent="center" alignItems="center">
          {" "}
          <Title align-self="center">{cdata.title}</Title>
          {icon}
        </Flex>
        <Subtitle color="text03">{cdata.text}</Subtitle>
      </Flex>
    </AspectRatioBox>
  )
}

const CardMobile = ({ display }) => {
  return (
    <Box mb={["64px", "32px"]} ml="-24px" mr="-24px" display={display}>
      <StyledNuca
        className="StyledNuca"
        withoutControls={true}
        slidesToShow={3}
        slideWidth="260px"
        cellAlign="center"
        wrapAround={true}
        autoplay={true}
        pauseOnHover={true}
      >
        {data.cards.map((card, idx) => {
          return (
            <Box key="idx" textAlign="center" width="240px" height="auto !important">
              <Card cdata={card} />
            </Box>
          )
        })}
      </StyledNuca>
    </Box>
  )
}

const CardDesktop = ({ display }) => {
  const Cards = data.cards.map((card, idx) => <Card key={idx} cdata={card}></Card>)
  return (
    <Box mt={["spc_2", "spc1", "spc2", "spc2", "spc2"]} display={display}>
      <Columns
        cols={[5]}
        hs={["0px", "spc", "spc1", "spc2", "spc3"]}
        mb={["spc", "spc2", "spc4", "spc6", "spc7"]}
      >
        {Cards}
      </Columns>
    </Box>
  )
}

const Stat = () => {
  return (
    <Box position="relative" zIndex={5} bg="brand05">
      <Block>
        <Box textAlign="center">
          <CssTitleSubido pb="spc4">
            <Text as="h4" textStyle="h4" color="text02" mb="spc1">
              {data.pretitle}
            </Text>
            <CardDesktop display={["none", "block"]} />
            <CardMobile display={["block", "none"]} />
            <Box mb={["spc1", "spc1", "spc1", "spc1", "spc2"]}>
              <Text as="h1" textStyle="h4">
                {data.title}
              </Text>
            </Box>
            <Box>
              <Text textStyle="subtitle" as="h2">
                {data.subtitle}
              </Text>
            </Box>
          </CssTitleSubido>
        </Box>
      </Block>
    </Box>
  )
}

export default Stat
