import React from "react"
import styled from "styled-components"
import { Flex, Text, Box } from "@sqymagma/elements"

const MyBox = styled(Flex)`
  background-color: ${p => p.theme.color.brand01};
  opacity: 0.8;
  border: 1px solid;
  border-color: ${p => p.theme.color.text02};
  outline: 6px solid;
  outline-color: ${p => p.theme.color.brand01};
`

const ClaimNormal = styled(Text)`
  color: ${p => p.theme.color.text02};
  font-family: "Rubik", "Helvetica", "Arial";
  font-size: 18px;
  line-height: 32px;
  font-weight: 300;

  ${p => p.theme.mq.s} {
    font-size: 23px;
    line-height: 48px;
  }
  ${p => p.theme.mq.m} {
    font-size: 24px;
    line-height: 40px;
  }

  ${p => p.theme.mq.xl} {
    font-size: 40px;
    line-height: 64px;
  }
`

const ClaimBold = styled(Text)`
  color: ${p => p.theme.color.text02};
  font-family: "DM Serif Display", serif;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;

  ${p => p.theme.mq.s} {
    font-size: 25px;
    line-height: 48px;
  }
  ${p => p.theme.mq.m} {
    font-size: 26px;
    line-height: 40px;
  }

  ${p => p.theme.mq.xl} {
    font-size: 44px;
    line-height: 64px;
  }
`

const Hero = ({ children, ...props }) => {
  return (
    <Flex position="relative" zIndex={0} width="100vw" height="100vh" {...props}>
      <Flex
        position="absolute"
        alignItems="center"
        justiftContent="center"
        css="background: rgb(0,0,0); background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.05) 50%, rgba(0,0,0,0.6) 100%);"
        zIndex="1"
        width="100vw"
        height="100vh"
      >
        <MyBox
          bg="brand01"
          alignItems="center"
          justifyContent="center"
          color="text02"
          mx={["auto"]}
          textAlign="center"
          width={["256px", "480px", "704px", "704px", "1085px"]}
        >
          <Box px={[32, 64]} py={[48, null, null, 64]}>
            <ClaimNormal>Convertimos antiguos </ClaimNormal>
            <ClaimBold>locales comerciales </ClaimBold>
            <ClaimNormal>en </ClaimNormal>
            <ClaimBold>inversiones </ClaimBold>
            <ClaimNormal>de </ClaimNormal>
            <ClaimBold>alta rentabilidad</ClaimBold>
          </Box>
        </MyBox>
      </Flex>

      {children}
    </Flex>
  )
}

export default Hero
