const data = {
  title: "Suscríbete a nuestro newsletter",
  text:
    "Te mandaremos nuevas oportunidades de inversión de adquisición de locales para cambiarles el uso de la vivienda",
  subtext: "",
  cta: "Suscribirme",
  idboton: "TM15-Home-MóduloSuscribirse-BotonSuscribirme",
}

export default data
