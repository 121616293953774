import React from "react"
import { Flex, Box, Columns, Text } from "@sqymagma/elements"
import styled from "styled-components"
import Block from "../components/block"
import { IcnSystem } from "../components/IconSystem/IcnSystem"
import BgImg from "gatsby-background-image-es5"
import { useStaticQuery, graphql } from "gatsby"

import data from "../data/m05"

const M = styled(Flex)`
  z-index: "100";
`

const Icn = styled(IcnSystem)`
  height: 56px;
  margin-bottom: 16px;
  ${p => p.theme.mq.s} {
    height: 64px;
    margin-bottom: 24px;
  }
  ${p => p.theme.mq.xl} {
    height: 96px;
    margin-bottom: 32px;
  }
`

const Card = ({ cdata }) => {
  return (
    <Box textAlign="center" maxWidth="480px" mx="auto">
      <Box mx={["spc_1", "spc", "spc2", "spc3", "spc4"]} my={["spc1", "0"]}>
        <Icn name={cdata.icon} fill="brand03" height="24px" />
        <Box>
          <Text textStyle="h4">{cdata.title}</Text>
        </Box>
        <Box pt={["spc_2", "spc_2", "spc_2", "spc", "spc1"]} />
        <Box textAlign={["center", "left"]}>
          <Text textStyle="p">{cdata.text}</Text>
        </Box>
      </Box>
    </Box>
  )
}
const MainPoints = () => {
  const imgs = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "planta.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const Cards = data.map((d, idx) => <Card key={idx} cdata={d} />)
  return (
    <BgImg fluid={imgs.placeholderImage.childImageSharp.fluid} position="relative" zIndex={0}>
      <M width="100%" alignItems="center" justifyContent="center">
        <Box py={["spc3", "spc4", "spc6", "spc8", "spc8"]}>
          <Block>
            <Columns cols={[1, 3]} bg="bg01" px={["spc", "spc", "spc2", "spc2", "spc4"]} py="spc8">
              {Cards}
            </Columns>
          </Block>
        </Box>
      </M>
    </BgImg>
  )
}

export default MainPoints
