import React from "react"
import Gatsbyimg from "./Gatsbyimg"

const CardHero03 = ({ filename, alt, ...props }) => {
  return (
    <Gatsbyimg
      alt={alt}
      filename={filename}
      css="object-fit:cover; position:absolute; width:100%; height:100%; z-index:0;"
      {...props}
    />
  )
}

export default CardHero03
