const m25 = {
  Title01: "¿Por qué invertir ahora en el cambio de uso de local a vivienda?",
  Title02: "¿Sabes cómo convertir un local en vivienda de alta rentabilidad?",
  p01:
    "La ciudad de Madrid se ha convertido en una de las ubicaciones más seguros y rentables para el mercado inmobilario.",
  p02:
    "Desde la búsqueda y selección de locales susceptibles a un cambio de uso, hasta la reforma y diseño de la nueva propiedad",
  cta01: "Invertir en Locales",
  cta02: "Cómo lo hacemos",
  link1: "invertir-en-locales-comerciales/",
  link2: "como-lo-hacemos/",
  id1: "TM13-Home-MóduloInvertirLocales-BotonInvertirLocales",
  id2: "TM14-Home-MóduloCambioUso-BotonComoHacemos",
}

export default m25
